const getLocationHash = () => {
  return location.hash
}

const setActiveTab = tab => {
  const headerHeight = document.querySelector('.header')?.clientHeight || 0
  setTimeout(() => {
    const elementPosition = tab.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerHeight
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }, 0)

  const activeTabWithMediaGallery = document.querySelector('.cmp-tabs__tabpanel--active .mediagallery:not(.custom-event-fired)')

  if (activeTabWithMediaGallery) {
    const changeTabEvent = new CustomEvent('changetab')
    window.dispatchEvent(changeTabEvent)
    activeTabWithMediaGallery.classList.add('custom-event-fired')
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const tabs = Array.from(document.querySelectorAll('.cmp-tabs__tab'))
  const locationHash = getLocationHash()

  const ActiveTabHeading = document.querySelector('.cmp-tabs.cmp-tabs-vertical .cmp-tab-active--heading')
  const TabsContainer = document.querySelector('.cmp-tabs.cmp-tabs-vertical .cmp-tabs__tablist')
  const FirstTab = TabsContainer.querySelector('.cmp-tabs__tab')
  FirstTab.appendChild(document.createElement('span'))
  const tabsExpanded = 'cmp-tabs__tab--expanded'

  const ActiveTabText = TabsContainer.querySelector('.cmp-tabs__tab--active').innerHTML
  ActiveTabHeading.innerHTML = ActiveTabText

  ActiveTabHeading.addEventListener('click', () => {
    TabsContainer.classList.add(tabsExpanded)
  })

  const FirstTabSpan = FirstTab.querySelector('span')

  FirstTabSpan.addEventListener('click', e => {
    TabsContainer.classList.remove(tabsExpanded)
    e.stopPropagation()
  })

  if (tabs.length) {
    tabs.forEach(el => {
      const elementId = `#${el.id}`
      el.addEventListener('click', e => {
        const TabElgrandparent = e.target.parentElement.parentElement
        if (TabElgrandparent.classList.contains('cmp-tabs-vertical')) {
          ActiveTabHeading.innerHTML = el.innerHTML
          TabsContainer.classList.remove(tabsExpanded)
          return false
        }
        setActiveTab(el)
      })

      if (locationHash && elementId === locationHash) {
        setActiveTab(el)
      }
    })
  }
})
