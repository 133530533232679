export const getLocationHash = () => {
  return location.hash
}

export const updateLocationHash = (anchor: string) => {
  if (history.replaceState) {
    const anchorPath = `${window.location.pathname.split('/').pop()}${window.location.search}${anchor}`
    history.replaceState(null, null, anchorPath)
  } else {
    location.hash = anchor
  }
}

export const navigationHeight = (navigationOffset: number, headerOffset: number, headerMobileHomeLink = 0): number => {
  if (navigationOffset === 0) {
    if (headerOffset === 0) {
      return headerMobileHomeLink
    }
    return headerOffset
  }
  return navigationOffset
}

export const elementTopPosition = (element: HTMLElement): number => {
  const bodyOffset = document.body.getBoundingClientRect().top
  return element.getBoundingClientRect().top - bodyOffset
}

