import { isMobile } from '@site/js/utils/breakpoint'
import { useRef,useState } from 'react'

import { elementTopPosition, getLocationHash } from '../AnchorNavigationHelperMethods'

let scrollingProgrammatically = null
const foldRatio = 0.2

export const useAnchorNavigation = props => {
  const [domPageTitles, setDomPageTitles] = useState(null)
  const [temporaryPageTitles, setTemporaryPageTitles] = useState(null)
  const [pageTitles, setPageTitles] = useState(null)
  const [isNavigationExpanded, setIsNavigationExpanded] = useState(false)
  const [isScrollingProgrammatically, setIsScrollingProgrammatically] = useState(false)
  const [stickyNavigationTopPosition, setStickyNavigationTopPosition] = useState(true)
  const [stickyNavigationContainerTopPosition, setStickyNavigationContainerTopPosition] = useState(null)
  const [isMobileLandscape, setIsMobileLandscape] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [locationHash, setLocationHash] = useState(location.hash)
  const [anchorNavigationActiveItemLabel, setAnchorNavigationActiveItemLabel] = useState(props.anchornavigationlabel || '')
  const anchorNavigationContainer = useRef(null)
  const anchorNavigationNavbar = useRef(null)
  const anchorNavigationList = useRef(null)

  // eslint-disable-next-line sonarjs/cognitive-complexity

  const setActiveLink = ({ id = null, isClicked = false, isLoadedWithHash = false } = {}) => {
    if (pageTitles) {
      let activeItemLabel = pageTitles[0]?.label
      if (isClicked || isLoadedWithHash) {
        setIsScrollingProgrammatically(true)
        clearTimeout(scrollingProgrammatically)
        scrollingProgrammatically = setTimeout(() => {
          setIsScrollingProgrammatically(false)
        }, 2000)
      }

      ;(domPageTitles as HTMLElement[])?.forEach((domPageTitle, index) => {
        if (domPageTitle.id === id) {
          activeItemLabel = pageTitles[index].label
          pageTitles[index].isActive = true
        } else {
          pageTitles[index].isActive = false
        }

        if ((isClicked || isLoadedWithHash) && domPageTitle.id === id) {
          const elementPosition = domPageTitle.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - calculateBufferHeight()
          window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
        }
      })

      if (id) {
        if (`#${id}` !== getLocationHash()) {
          setLocationHash(`#${id}`)
        }
      } else {
        setLocationHash('')
      }
      setAnchorNavigationActiveItemLabel(activeItemLabel)
    }
  }

  const calculateBufferHeight = () => {
    if (isMobile) {
      return 100
    }
    return stickyNavigationTopPosition ? 220 : 100
  }

  const getTitlesAboveFold = (): HTMLElement[] => {
    const foldPosition = Math.floor((isMobileLandscape ? window.innerWidth : window.innerHeight) * foldRatio)
    return (domPageTitles as HTMLElement[])?.filter(title => {
      const titleTopPosition = elementTopPosition(title) - foldPosition
      return window.scrollY >= titleTopPosition
    })
  }

  return {
    temporaryPageTitles,
    setTemporaryPageTitles,
    domPageTitles,
    setDomPageTitles,
    locationHash,
    pageTitles,
    setPageTitles,
    isScrollingProgrammatically,
    isOverflowing,
    setIsOverflowing,
    setIsMobileLandscape,
    stickyNavigationTopPosition,
    setStickyNavigationTopPosition,
    stickyNavigationContainerTopPosition,
    setStickyNavigationContainerTopPosition,
    isNavigationExpanded,
    setIsNavigationExpanded,
    anchorNavigationActiveItemLabel,
    setActiveLink,
    getTitlesAboveFold,
    anchorNavigationContainer,
    anchorNavigationNavbar,
    anchorNavigationList,
  }
}
